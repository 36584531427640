import { __assign, __extends, __read } from "tslib";
import { DynamoDBServiceException as __BaseException } from "./DynamoDBServiceException";
export var ArchivalSummary;
(function (ArchivalSummary) {
    ArchivalSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ArchivalSummary || (ArchivalSummary = {}));
export var AttributeDefinition;
(function (AttributeDefinition) {
    AttributeDefinition.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AttributeDefinition || (AttributeDefinition = {}));
export var AutoScalingTargetTrackingScalingPolicyConfigurationDescription;
(function (AutoScalingTargetTrackingScalingPolicyConfigurationDescription) {
    AutoScalingTargetTrackingScalingPolicyConfigurationDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AutoScalingTargetTrackingScalingPolicyConfigurationDescription || (AutoScalingTargetTrackingScalingPolicyConfigurationDescription = {}));
export var AutoScalingPolicyDescription;
(function (AutoScalingPolicyDescription) {
    AutoScalingPolicyDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AutoScalingPolicyDescription || (AutoScalingPolicyDescription = {}));
export var AutoScalingTargetTrackingScalingPolicyConfigurationUpdate;
(function (AutoScalingTargetTrackingScalingPolicyConfigurationUpdate) {
    AutoScalingTargetTrackingScalingPolicyConfigurationUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AutoScalingTargetTrackingScalingPolicyConfigurationUpdate || (AutoScalingTargetTrackingScalingPolicyConfigurationUpdate = {}));
export var AutoScalingPolicyUpdate;
(function (AutoScalingPolicyUpdate) {
    AutoScalingPolicyUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AutoScalingPolicyUpdate || (AutoScalingPolicyUpdate = {}));
export var AutoScalingSettingsDescription;
(function (AutoScalingSettingsDescription) {
    AutoScalingSettingsDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AutoScalingSettingsDescription || (AutoScalingSettingsDescription = {}));
export var AutoScalingSettingsUpdate;
(function (AutoScalingSettingsUpdate) {
    AutoScalingSettingsUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AutoScalingSettingsUpdate || (AutoScalingSettingsUpdate = {}));
export var BackupType;
(function (BackupType) {
    BackupType["AWS_BACKUP"] = "AWS_BACKUP";
    BackupType["SYSTEM"] = "SYSTEM";
    BackupType["USER"] = "USER";
})(BackupType || (BackupType = {}));
export var BackupDetails;
(function (BackupDetails) {
    BackupDetails.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BackupDetails || (BackupDetails = {}));
export var KeySchemaElement;
(function (KeySchemaElement) {
    KeySchemaElement.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KeySchemaElement || (KeySchemaElement = {}));
export var ProvisionedThroughput;
(function (ProvisionedThroughput) {
    ProvisionedThroughput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProvisionedThroughput || (ProvisionedThroughput = {}));
export var SourceTableDetails;
(function (SourceTableDetails) {
    SourceTableDetails.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SourceTableDetails || (SourceTableDetails = {}));
export var Projection;
(function (Projection) {
    Projection.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Projection || (Projection = {}));
export var GlobalSecondaryIndexInfo;
(function (GlobalSecondaryIndexInfo) {
    GlobalSecondaryIndexInfo.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalSecondaryIndexInfo || (GlobalSecondaryIndexInfo = {}));
export var LocalSecondaryIndexInfo;
(function (LocalSecondaryIndexInfo) {
    LocalSecondaryIndexInfo.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LocalSecondaryIndexInfo || (LocalSecondaryIndexInfo = {}));
export var SSEDescription;
(function (SSEDescription) {
    SSEDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SSEDescription || (SSEDescription = {}));
export var StreamSpecification;
(function (StreamSpecification) {
    StreamSpecification.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(StreamSpecification || (StreamSpecification = {}));
export var TimeToLiveDescription;
(function (TimeToLiveDescription) {
    TimeToLiveDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TimeToLiveDescription || (TimeToLiveDescription = {}));
export var SourceTableFeatureDetails;
(function (SourceTableFeatureDetails) {
    SourceTableFeatureDetails.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SourceTableFeatureDetails || (SourceTableFeatureDetails = {}));
export var BackupDescription;
(function (BackupDescription) {
    BackupDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BackupDescription || (BackupDescription = {}));
var BackupInUseException = (function (_super) {
    __extends(BackupInUseException, _super);
    function BackupInUseException(opts) {
        var _this = _super.call(this, __assign({ name: "BackupInUseException", $fault: "client" }, opts)) || this;
        _this.name = "BackupInUseException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BackupInUseException.prototype);
        return _this;
    }
    return BackupInUseException;
}(__BaseException));
export { BackupInUseException };
var BackupNotFoundException = (function (_super) {
    __extends(BackupNotFoundException, _super);
    function BackupNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "BackupNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "BackupNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BackupNotFoundException.prototype);
        return _this;
    }
    return BackupNotFoundException;
}(__BaseException));
export { BackupNotFoundException };
export var BackupSummary;
(function (BackupSummary) {
    BackupSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BackupSummary || (BackupSummary = {}));
export var BackupTypeFilter;
(function (BackupTypeFilter) {
    BackupTypeFilter["ALL"] = "ALL";
    BackupTypeFilter["AWS_BACKUP"] = "AWS_BACKUP";
    BackupTypeFilter["SYSTEM"] = "SYSTEM";
    BackupTypeFilter["USER"] = "USER";
})(BackupTypeFilter || (BackupTypeFilter = {}));
export var Capacity;
(function (Capacity) {
    Capacity.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Capacity || (Capacity = {}));
export var ConsumedCapacity;
(function (ConsumedCapacity) {
    ConsumedCapacity.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConsumedCapacity || (ConsumedCapacity = {}));
export var BatchStatementErrorCodeEnum;
(function (BatchStatementErrorCodeEnum) {
    BatchStatementErrorCodeEnum["AccessDenied"] = "AccessDenied";
    BatchStatementErrorCodeEnum["ConditionalCheckFailed"] = "ConditionalCheckFailed";
    BatchStatementErrorCodeEnum["DuplicateItem"] = "DuplicateItem";
    BatchStatementErrorCodeEnum["InternalServerError"] = "InternalServerError";
    BatchStatementErrorCodeEnum["ItemCollectionSizeLimitExceeded"] = "ItemCollectionSizeLimitExceeded";
    BatchStatementErrorCodeEnum["ProvisionedThroughputExceeded"] = "ProvisionedThroughputExceeded";
    BatchStatementErrorCodeEnum["RequestLimitExceeded"] = "RequestLimitExceeded";
    BatchStatementErrorCodeEnum["ResourceNotFound"] = "ResourceNotFound";
    BatchStatementErrorCodeEnum["ThrottlingError"] = "ThrottlingError";
    BatchStatementErrorCodeEnum["TransactionConflict"] = "TransactionConflict";
    BatchStatementErrorCodeEnum["ValidationError"] = "ValidationError";
})(BatchStatementErrorCodeEnum || (BatchStatementErrorCodeEnum = {}));
export var BatchStatementError;
(function (BatchStatementError) {
    BatchStatementError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchStatementError || (BatchStatementError = {}));
var InternalServerError = (function (_super) {
    __extends(InternalServerError, _super);
    function InternalServerError(opts) {
        var _this = _super.call(this, __assign({ name: "InternalServerError", $fault: "server" }, opts)) || this;
        _this.name = "InternalServerError";
        _this.$fault = "server";
        Object.setPrototypeOf(_this, InternalServerError.prototype);
        return _this;
    }
    return InternalServerError;
}(__BaseException));
export { InternalServerError };
var RequestLimitExceeded = (function (_super) {
    __extends(RequestLimitExceeded, _super);
    function RequestLimitExceeded(opts) {
        var _this = _super.call(this, __assign({ name: "RequestLimitExceeded", $fault: "client" }, opts)) || this;
        _this.name = "RequestLimitExceeded";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, RequestLimitExceeded.prototype);
        return _this;
    }
    return RequestLimitExceeded;
}(__BaseException));
export { RequestLimitExceeded };
var InvalidEndpointException = (function (_super) {
    __extends(InvalidEndpointException, _super);
    function InvalidEndpointException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidEndpointException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidEndpointException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidEndpointException.prototype);
        _this.Message = opts.Message;
        return _this;
    }
    return InvalidEndpointException;
}(__BaseException));
export { InvalidEndpointException };
var ProvisionedThroughputExceededException = (function (_super) {
    __extends(ProvisionedThroughputExceededException, _super);
    function ProvisionedThroughputExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "ProvisionedThroughputExceededException", $fault: "client" }, opts)) || this;
        _this.name = "ProvisionedThroughputExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ProvisionedThroughputExceededException.prototype);
        return _this;
    }
    return ProvisionedThroughputExceededException;
}(__BaseException));
export { ProvisionedThroughputExceededException };
var ResourceNotFoundException = (function (_super) {
    __extends(ResourceNotFoundException, _super);
    function ResourceNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "ResourceNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "ResourceNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ResourceNotFoundException.prototype);
        return _this;
    }
    return ResourceNotFoundException;
}(__BaseException));
export { ResourceNotFoundException };
var ItemCollectionSizeLimitExceededException = (function (_super) {
    __extends(ItemCollectionSizeLimitExceededException, _super);
    function ItemCollectionSizeLimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "ItemCollectionSizeLimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "ItemCollectionSizeLimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ItemCollectionSizeLimitExceededException.prototype);
        return _this;
    }
    return ItemCollectionSizeLimitExceededException;
}(__BaseException));
export { ItemCollectionSizeLimitExceededException };
export var BillingModeSummary;
(function (BillingModeSummary) {
    BillingModeSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BillingModeSummary || (BillingModeSummary = {}));
var ConditionalCheckFailedException = (function (_super) {
    __extends(ConditionalCheckFailedException, _super);
    function ConditionalCheckFailedException(opts) {
        var _this = _super.call(this, __assign({ name: "ConditionalCheckFailedException", $fault: "client" }, opts)) || this;
        _this.name = "ConditionalCheckFailedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ConditionalCheckFailedException.prototype);
        return _this;
    }
    return ConditionalCheckFailedException;
}(__BaseException));
export { ConditionalCheckFailedException };
export var PointInTimeRecoveryDescription;
(function (PointInTimeRecoveryDescription) {
    PointInTimeRecoveryDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PointInTimeRecoveryDescription || (PointInTimeRecoveryDescription = {}));
export var ContinuousBackupsDescription;
(function (ContinuousBackupsDescription) {
    ContinuousBackupsDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ContinuousBackupsDescription || (ContinuousBackupsDescription = {}));
var ContinuousBackupsUnavailableException = (function (_super) {
    __extends(ContinuousBackupsUnavailableException, _super);
    function ContinuousBackupsUnavailableException(opts) {
        var _this = _super.call(this, __assign({ name: "ContinuousBackupsUnavailableException", $fault: "client" }, opts)) || this;
        _this.name = "ContinuousBackupsUnavailableException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ContinuousBackupsUnavailableException.prototype);
        return _this;
    }
    return ContinuousBackupsUnavailableException;
}(__BaseException));
export { ContinuousBackupsUnavailableException };
export var ContributorInsightsSummary;
(function (ContributorInsightsSummary) {
    ContributorInsightsSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ContributorInsightsSummary || (ContributorInsightsSummary = {}));
export var CreateBackupInput;
(function (CreateBackupInput) {
    CreateBackupInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateBackupInput || (CreateBackupInput = {}));
export var CreateBackupOutput;
(function (CreateBackupOutput) {
    CreateBackupOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateBackupOutput || (CreateBackupOutput = {}));
var LimitExceededException = (function (_super) {
    __extends(LimitExceededException, _super);
    function LimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "LimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "LimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, LimitExceededException.prototype);
        return _this;
    }
    return LimitExceededException;
}(__BaseException));
export { LimitExceededException };
var TableInUseException = (function (_super) {
    __extends(TableInUseException, _super);
    function TableInUseException(opts) {
        var _this = _super.call(this, __assign({ name: "TableInUseException", $fault: "client" }, opts)) || this;
        _this.name = "TableInUseException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TableInUseException.prototype);
        return _this;
    }
    return TableInUseException;
}(__BaseException));
export { TableInUseException };
var TableNotFoundException = (function (_super) {
    __extends(TableNotFoundException, _super);
    function TableNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "TableNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "TableNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TableNotFoundException.prototype);
        return _this;
    }
    return TableNotFoundException;
}(__BaseException));
export { TableNotFoundException };
export var CreateGlobalSecondaryIndexAction;
(function (CreateGlobalSecondaryIndexAction) {
    CreateGlobalSecondaryIndexAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGlobalSecondaryIndexAction || (CreateGlobalSecondaryIndexAction = {}));
export var Replica;
(function (Replica) {
    Replica.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Replica || (Replica = {}));
export var CreateGlobalTableInput;
(function (CreateGlobalTableInput) {
    CreateGlobalTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGlobalTableInput || (CreateGlobalTableInput = {}));
export var ProvisionedThroughputOverride;
(function (ProvisionedThroughputOverride) {
    ProvisionedThroughputOverride.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProvisionedThroughputOverride || (ProvisionedThroughputOverride = {}));
export var ReplicaGlobalSecondaryIndexDescription;
(function (ReplicaGlobalSecondaryIndexDescription) {
    ReplicaGlobalSecondaryIndexDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaGlobalSecondaryIndexDescription || (ReplicaGlobalSecondaryIndexDescription = {}));
export var TableClass;
(function (TableClass) {
    TableClass["STANDARD"] = "STANDARD";
    TableClass["STANDARD_INFREQUENT_ACCESS"] = "STANDARD_INFREQUENT_ACCESS";
})(TableClass || (TableClass = {}));
export var TableClassSummary;
(function (TableClassSummary) {
    TableClassSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TableClassSummary || (TableClassSummary = {}));
export var ReplicaDescription;
(function (ReplicaDescription) {
    ReplicaDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaDescription || (ReplicaDescription = {}));
export var GlobalTableDescription;
(function (GlobalTableDescription) {
    GlobalTableDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalTableDescription || (GlobalTableDescription = {}));
export var CreateGlobalTableOutput;
(function (CreateGlobalTableOutput) {
    CreateGlobalTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGlobalTableOutput || (CreateGlobalTableOutput = {}));
var GlobalTableAlreadyExistsException = (function (_super) {
    __extends(GlobalTableAlreadyExistsException, _super);
    function GlobalTableAlreadyExistsException(opts) {
        var _this = _super.call(this, __assign({ name: "GlobalTableAlreadyExistsException", $fault: "client" }, opts)) || this;
        _this.name = "GlobalTableAlreadyExistsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, GlobalTableAlreadyExistsException.prototype);
        return _this;
    }
    return GlobalTableAlreadyExistsException;
}(__BaseException));
export { GlobalTableAlreadyExistsException };
export var CreateReplicaAction;
(function (CreateReplicaAction) {
    CreateReplicaAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateReplicaAction || (CreateReplicaAction = {}));
export var ReplicaGlobalSecondaryIndex;
(function (ReplicaGlobalSecondaryIndex) {
    ReplicaGlobalSecondaryIndex.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaGlobalSecondaryIndex || (ReplicaGlobalSecondaryIndex = {}));
export var CreateReplicationGroupMemberAction;
(function (CreateReplicationGroupMemberAction) {
    CreateReplicationGroupMemberAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateReplicationGroupMemberAction || (CreateReplicationGroupMemberAction = {}));
export var GlobalSecondaryIndex;
(function (GlobalSecondaryIndex) {
    GlobalSecondaryIndex.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalSecondaryIndex || (GlobalSecondaryIndex = {}));
export var LocalSecondaryIndex;
(function (LocalSecondaryIndex) {
    LocalSecondaryIndex.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LocalSecondaryIndex || (LocalSecondaryIndex = {}));
export var SSESpecification;
(function (SSESpecification) {
    SSESpecification.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SSESpecification || (SSESpecification = {}));
export var Tag;
(function (Tag) {
    Tag.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Tag || (Tag = {}));
export var CreateTableInput;
(function (CreateTableInput) {
    CreateTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateTableInput || (CreateTableInput = {}));
export var ProvisionedThroughputDescription;
(function (ProvisionedThroughputDescription) {
    ProvisionedThroughputDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProvisionedThroughputDescription || (ProvisionedThroughputDescription = {}));
export var GlobalSecondaryIndexDescription;
(function (GlobalSecondaryIndexDescription) {
    GlobalSecondaryIndexDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalSecondaryIndexDescription || (GlobalSecondaryIndexDescription = {}));
export var LocalSecondaryIndexDescription;
(function (LocalSecondaryIndexDescription) {
    LocalSecondaryIndexDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LocalSecondaryIndexDescription || (LocalSecondaryIndexDescription = {}));
export var RestoreSummary;
(function (RestoreSummary) {
    RestoreSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RestoreSummary || (RestoreSummary = {}));
export var TableDescription;
(function (TableDescription) {
    TableDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TableDescription || (TableDescription = {}));
export var CreateTableOutput;
(function (CreateTableOutput) {
    CreateTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateTableOutput || (CreateTableOutput = {}));
var ResourceInUseException = (function (_super) {
    __extends(ResourceInUseException, _super);
    function ResourceInUseException(opts) {
        var _this = _super.call(this, __assign({ name: "ResourceInUseException", $fault: "client" }, opts)) || this;
        _this.name = "ResourceInUseException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ResourceInUseException.prototype);
        return _this;
    }
    return ResourceInUseException;
}(__BaseException));
export { ResourceInUseException };
export var DeleteBackupInput;
(function (DeleteBackupInput) {
    DeleteBackupInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteBackupInput || (DeleteBackupInput = {}));
export var DeleteBackupOutput;
(function (DeleteBackupOutput) {
    DeleteBackupOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteBackupOutput || (DeleteBackupOutput = {}));
export var DeleteGlobalSecondaryIndexAction;
(function (DeleteGlobalSecondaryIndexAction) {
    DeleteGlobalSecondaryIndexAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteGlobalSecondaryIndexAction || (DeleteGlobalSecondaryIndexAction = {}));
var TransactionConflictException = (function (_super) {
    __extends(TransactionConflictException, _super);
    function TransactionConflictException(opts) {
        var _this = _super.call(this, __assign({ name: "TransactionConflictException", $fault: "client" }, opts)) || this;
        _this.name = "TransactionConflictException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TransactionConflictException.prototype);
        return _this;
    }
    return TransactionConflictException;
}(__BaseException));
export { TransactionConflictException };
export var DeleteReplicaAction;
(function (DeleteReplicaAction) {
    DeleteReplicaAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteReplicaAction || (DeleteReplicaAction = {}));
export var DeleteReplicationGroupMemberAction;
(function (DeleteReplicationGroupMemberAction) {
    DeleteReplicationGroupMemberAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteReplicationGroupMemberAction || (DeleteReplicationGroupMemberAction = {}));
export var DeleteTableInput;
(function (DeleteTableInput) {
    DeleteTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteTableInput || (DeleteTableInput = {}));
export var DeleteTableOutput;
(function (DeleteTableOutput) {
    DeleteTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteTableOutput || (DeleteTableOutput = {}));
export var DescribeBackupInput;
(function (DescribeBackupInput) {
    DescribeBackupInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeBackupInput || (DescribeBackupInput = {}));
export var DescribeBackupOutput;
(function (DescribeBackupOutput) {
    DescribeBackupOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeBackupOutput || (DescribeBackupOutput = {}));
export var DescribeContinuousBackupsInput;
(function (DescribeContinuousBackupsInput) {
    DescribeContinuousBackupsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeContinuousBackupsInput || (DescribeContinuousBackupsInput = {}));
export var DescribeContinuousBackupsOutput;
(function (DescribeContinuousBackupsOutput) {
    DescribeContinuousBackupsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeContinuousBackupsOutput || (DescribeContinuousBackupsOutput = {}));
export var DescribeContributorInsightsInput;
(function (DescribeContributorInsightsInput) {
    DescribeContributorInsightsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeContributorInsightsInput || (DescribeContributorInsightsInput = {}));
export var FailureException;
(function (FailureException) {
    FailureException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(FailureException || (FailureException = {}));
export var DescribeContributorInsightsOutput;
(function (DescribeContributorInsightsOutput) {
    DescribeContributorInsightsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeContributorInsightsOutput || (DescribeContributorInsightsOutput = {}));
export var DescribeEndpointsRequest;
(function (DescribeEndpointsRequest) {
    DescribeEndpointsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeEndpointsRequest || (DescribeEndpointsRequest = {}));
export var Endpoint;
(function (Endpoint) {
    Endpoint.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Endpoint || (Endpoint = {}));
export var DescribeEndpointsResponse;
(function (DescribeEndpointsResponse) {
    DescribeEndpointsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeEndpointsResponse || (DescribeEndpointsResponse = {}));
export var DescribeExportInput;
(function (DescribeExportInput) {
    DescribeExportInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeExportInput || (DescribeExportInput = {}));
export var ExportFormat;
(function (ExportFormat) {
    ExportFormat["DYNAMODB_JSON"] = "DYNAMODB_JSON";
    ExportFormat["ION"] = "ION";
})(ExportFormat || (ExportFormat = {}));
export var ExportStatus;
(function (ExportStatus) {
    ExportStatus["COMPLETED"] = "COMPLETED";
    ExportStatus["FAILED"] = "FAILED";
    ExportStatus["IN_PROGRESS"] = "IN_PROGRESS";
})(ExportStatus || (ExportStatus = {}));
export var ExportDescription;
(function (ExportDescription) {
    ExportDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ExportDescription || (ExportDescription = {}));
export var DescribeExportOutput;
(function (DescribeExportOutput) {
    DescribeExportOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeExportOutput || (DescribeExportOutput = {}));
var ExportNotFoundException = (function (_super) {
    __extends(ExportNotFoundException, _super);
    function ExportNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "ExportNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "ExportNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ExportNotFoundException.prototype);
        return _this;
    }
    return ExportNotFoundException;
}(__BaseException));
export { ExportNotFoundException };
export var DescribeGlobalTableInput;
(function (DescribeGlobalTableInput) {
    DescribeGlobalTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeGlobalTableInput || (DescribeGlobalTableInput = {}));
export var DescribeGlobalTableOutput;
(function (DescribeGlobalTableOutput) {
    DescribeGlobalTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeGlobalTableOutput || (DescribeGlobalTableOutput = {}));
var GlobalTableNotFoundException = (function (_super) {
    __extends(GlobalTableNotFoundException, _super);
    function GlobalTableNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "GlobalTableNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "GlobalTableNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, GlobalTableNotFoundException.prototype);
        return _this;
    }
    return GlobalTableNotFoundException;
}(__BaseException));
export { GlobalTableNotFoundException };
export var DescribeGlobalTableSettingsInput;
(function (DescribeGlobalTableSettingsInput) {
    DescribeGlobalTableSettingsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeGlobalTableSettingsInput || (DescribeGlobalTableSettingsInput = {}));
export var ReplicaGlobalSecondaryIndexSettingsDescription;
(function (ReplicaGlobalSecondaryIndexSettingsDescription) {
    ReplicaGlobalSecondaryIndexSettingsDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaGlobalSecondaryIndexSettingsDescription || (ReplicaGlobalSecondaryIndexSettingsDescription = {}));
export var ReplicaSettingsDescription;
(function (ReplicaSettingsDescription) {
    ReplicaSettingsDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaSettingsDescription || (ReplicaSettingsDescription = {}));
export var DescribeGlobalTableSettingsOutput;
(function (DescribeGlobalTableSettingsOutput) {
    DescribeGlobalTableSettingsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeGlobalTableSettingsOutput || (DescribeGlobalTableSettingsOutput = {}));
export var DescribeKinesisStreamingDestinationInput;
(function (DescribeKinesisStreamingDestinationInput) {
    DescribeKinesisStreamingDestinationInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeKinesisStreamingDestinationInput || (DescribeKinesisStreamingDestinationInput = {}));
export var KinesisDataStreamDestination;
(function (KinesisDataStreamDestination) {
    KinesisDataStreamDestination.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KinesisDataStreamDestination || (KinesisDataStreamDestination = {}));
export var DescribeKinesisStreamingDestinationOutput;
(function (DescribeKinesisStreamingDestinationOutput) {
    DescribeKinesisStreamingDestinationOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeKinesisStreamingDestinationOutput || (DescribeKinesisStreamingDestinationOutput = {}));
export var DescribeLimitsInput;
(function (DescribeLimitsInput) {
    DescribeLimitsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeLimitsInput || (DescribeLimitsInput = {}));
export var DescribeLimitsOutput;
(function (DescribeLimitsOutput) {
    DescribeLimitsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeLimitsOutput || (DescribeLimitsOutput = {}));
export var DescribeTableInput;
(function (DescribeTableInput) {
    DescribeTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTableInput || (DescribeTableInput = {}));
export var DescribeTableOutput;
(function (DescribeTableOutput) {
    DescribeTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTableOutput || (DescribeTableOutput = {}));
export var DescribeTableReplicaAutoScalingInput;
(function (DescribeTableReplicaAutoScalingInput) {
    DescribeTableReplicaAutoScalingInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTableReplicaAutoScalingInput || (DescribeTableReplicaAutoScalingInput = {}));
export var ReplicaGlobalSecondaryIndexAutoScalingDescription;
(function (ReplicaGlobalSecondaryIndexAutoScalingDescription) {
    ReplicaGlobalSecondaryIndexAutoScalingDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaGlobalSecondaryIndexAutoScalingDescription || (ReplicaGlobalSecondaryIndexAutoScalingDescription = {}));
export var ReplicaAutoScalingDescription;
(function (ReplicaAutoScalingDescription) {
    ReplicaAutoScalingDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaAutoScalingDescription || (ReplicaAutoScalingDescription = {}));
export var TableAutoScalingDescription;
(function (TableAutoScalingDescription) {
    TableAutoScalingDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TableAutoScalingDescription || (TableAutoScalingDescription = {}));
export var DescribeTableReplicaAutoScalingOutput;
(function (DescribeTableReplicaAutoScalingOutput) {
    DescribeTableReplicaAutoScalingOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTableReplicaAutoScalingOutput || (DescribeTableReplicaAutoScalingOutput = {}));
export var DescribeTimeToLiveInput;
(function (DescribeTimeToLiveInput) {
    DescribeTimeToLiveInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTimeToLiveInput || (DescribeTimeToLiveInput = {}));
export var DescribeTimeToLiveOutput;
(function (DescribeTimeToLiveOutput) {
    DescribeTimeToLiveOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTimeToLiveOutput || (DescribeTimeToLiveOutput = {}));
export var KinesisStreamingDestinationInput;
(function (KinesisStreamingDestinationInput) {
    KinesisStreamingDestinationInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KinesisStreamingDestinationInput || (KinesisStreamingDestinationInput = {}));
export var KinesisStreamingDestinationOutput;
(function (KinesisStreamingDestinationOutput) {
    KinesisStreamingDestinationOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(KinesisStreamingDestinationOutput || (KinesisStreamingDestinationOutput = {}));
var DuplicateItemException = (function (_super) {
    __extends(DuplicateItemException, _super);
    function DuplicateItemException(opts) {
        var _this = _super.call(this, __assign({ name: "DuplicateItemException", $fault: "client" }, opts)) || this;
        _this.name = "DuplicateItemException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, DuplicateItemException.prototype);
        return _this;
    }
    return DuplicateItemException;
}(__BaseException));
export { DuplicateItemException };
var IdempotentParameterMismatchException = (function (_super) {
    __extends(IdempotentParameterMismatchException, _super);
    function IdempotentParameterMismatchException(opts) {
        var _this = _super.call(this, __assign({ name: "IdempotentParameterMismatchException", $fault: "client" }, opts)) || this;
        _this.name = "IdempotentParameterMismatchException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, IdempotentParameterMismatchException.prototype);
        _this.Message = opts.Message;
        return _this;
    }
    return IdempotentParameterMismatchException;
}(__BaseException));
export { IdempotentParameterMismatchException };
var TransactionInProgressException = (function (_super) {
    __extends(TransactionInProgressException, _super);
    function TransactionInProgressException(opts) {
        var _this = _super.call(this, __assign({ name: "TransactionInProgressException", $fault: "client" }, opts)) || this;
        _this.name = "TransactionInProgressException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TransactionInProgressException.prototype);
        _this.Message = opts.Message;
        return _this;
    }
    return TransactionInProgressException;
}(__BaseException));
export { TransactionInProgressException };
var ExportConflictException = (function (_super) {
    __extends(ExportConflictException, _super);
    function ExportConflictException(opts) {
        var _this = _super.call(this, __assign({ name: "ExportConflictException", $fault: "client" }, opts)) || this;
        _this.name = "ExportConflictException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ExportConflictException.prototype);
        return _this;
    }
    return ExportConflictException;
}(__BaseException));
export { ExportConflictException };
export var ExportTableToPointInTimeInput;
(function (ExportTableToPointInTimeInput) {
    ExportTableToPointInTimeInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ExportTableToPointInTimeInput || (ExportTableToPointInTimeInput = {}));
export var ExportTableToPointInTimeOutput;
(function (ExportTableToPointInTimeOutput) {
    ExportTableToPointInTimeOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ExportTableToPointInTimeOutput || (ExportTableToPointInTimeOutput = {}));
var InvalidExportTimeException = (function (_super) {
    __extends(InvalidExportTimeException, _super);
    function InvalidExportTimeException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidExportTimeException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidExportTimeException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidExportTimeException.prototype);
        return _this;
    }
    return InvalidExportTimeException;
}(__BaseException));
export { InvalidExportTimeException };
var PointInTimeRecoveryUnavailableException = (function (_super) {
    __extends(PointInTimeRecoveryUnavailableException, _super);
    function PointInTimeRecoveryUnavailableException(opts) {
        var _this = _super.call(this, __assign({ name: "PointInTimeRecoveryUnavailableException", $fault: "client" }, opts)) || this;
        _this.name = "PointInTimeRecoveryUnavailableException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, PointInTimeRecoveryUnavailableException.prototype);
        return _this;
    }
    return PointInTimeRecoveryUnavailableException;
}(__BaseException));
export { PointInTimeRecoveryUnavailableException };
export var ListBackupsInput;
(function (ListBackupsInput) {
    ListBackupsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListBackupsInput || (ListBackupsInput = {}));
export var ListBackupsOutput;
(function (ListBackupsOutput) {
    ListBackupsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListBackupsOutput || (ListBackupsOutput = {}));
export var ListContributorInsightsInput;
(function (ListContributorInsightsInput) {
    ListContributorInsightsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListContributorInsightsInput || (ListContributorInsightsInput = {}));
export var ListContributorInsightsOutput;
(function (ListContributorInsightsOutput) {
    ListContributorInsightsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListContributorInsightsOutput || (ListContributorInsightsOutput = {}));
export var ListExportsInput;
(function (ListExportsInput) {
    ListExportsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListExportsInput || (ListExportsInput = {}));
export var ExportSummary;
(function (ExportSummary) {
    ExportSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ExportSummary || (ExportSummary = {}));
export var ListExportsOutput;
(function (ListExportsOutput) {
    ListExportsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListExportsOutput || (ListExportsOutput = {}));
export var ListGlobalTablesInput;
(function (ListGlobalTablesInput) {
    ListGlobalTablesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGlobalTablesInput || (ListGlobalTablesInput = {}));
export var GlobalTable;
(function (GlobalTable) {
    GlobalTable.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalTable || (GlobalTable = {}));
export var ListGlobalTablesOutput;
(function (ListGlobalTablesOutput) {
    ListGlobalTablesOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGlobalTablesOutput || (ListGlobalTablesOutput = {}));
export var ListTablesInput;
(function (ListTablesInput) {
    ListTablesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTablesInput || (ListTablesInput = {}));
export var ListTablesOutput;
(function (ListTablesOutput) {
    ListTablesOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTablesOutput || (ListTablesOutput = {}));
export var ListTagsOfResourceInput;
(function (ListTagsOfResourceInput) {
    ListTagsOfResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsOfResourceInput || (ListTagsOfResourceInput = {}));
export var ListTagsOfResourceOutput;
(function (ListTagsOfResourceOutput) {
    ListTagsOfResourceOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsOfResourceOutput || (ListTagsOfResourceOutput = {}));
export var RestoreTableFromBackupInput;
(function (RestoreTableFromBackupInput) {
    RestoreTableFromBackupInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RestoreTableFromBackupInput || (RestoreTableFromBackupInput = {}));
export var RestoreTableFromBackupOutput;
(function (RestoreTableFromBackupOutput) {
    RestoreTableFromBackupOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RestoreTableFromBackupOutput || (RestoreTableFromBackupOutput = {}));
var TableAlreadyExistsException = (function (_super) {
    __extends(TableAlreadyExistsException, _super);
    function TableAlreadyExistsException(opts) {
        var _this = _super.call(this, __assign({ name: "TableAlreadyExistsException", $fault: "client" }, opts)) || this;
        _this.name = "TableAlreadyExistsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TableAlreadyExistsException.prototype);
        return _this;
    }
    return TableAlreadyExistsException;
}(__BaseException));
export { TableAlreadyExistsException };
var InvalidRestoreTimeException = (function (_super) {
    __extends(InvalidRestoreTimeException, _super);
    function InvalidRestoreTimeException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidRestoreTimeException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidRestoreTimeException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidRestoreTimeException.prototype);
        return _this;
    }
    return InvalidRestoreTimeException;
}(__BaseException));
export { InvalidRestoreTimeException };
export var RestoreTableToPointInTimeInput;
(function (RestoreTableToPointInTimeInput) {
    RestoreTableToPointInTimeInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RestoreTableToPointInTimeInput || (RestoreTableToPointInTimeInput = {}));
export var RestoreTableToPointInTimeOutput;
(function (RestoreTableToPointInTimeOutput) {
    RestoreTableToPointInTimeOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RestoreTableToPointInTimeOutput || (RestoreTableToPointInTimeOutput = {}));
export var TagResourceInput;
(function (TagResourceInput) {
    TagResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceInput || (TagResourceInput = {}));
export var UntagResourceInput;
(function (UntagResourceInput) {
    UntagResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceInput || (UntagResourceInput = {}));
export var PointInTimeRecoverySpecification;
(function (PointInTimeRecoverySpecification) {
    PointInTimeRecoverySpecification.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PointInTimeRecoverySpecification || (PointInTimeRecoverySpecification = {}));
export var UpdateContinuousBackupsInput;
(function (UpdateContinuousBackupsInput) {
    UpdateContinuousBackupsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateContinuousBackupsInput || (UpdateContinuousBackupsInput = {}));
export var UpdateContinuousBackupsOutput;
(function (UpdateContinuousBackupsOutput) {
    UpdateContinuousBackupsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateContinuousBackupsOutput || (UpdateContinuousBackupsOutput = {}));
export var UpdateContributorInsightsInput;
(function (UpdateContributorInsightsInput) {
    UpdateContributorInsightsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateContributorInsightsInput || (UpdateContributorInsightsInput = {}));
export var UpdateContributorInsightsOutput;
(function (UpdateContributorInsightsOutput) {
    UpdateContributorInsightsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateContributorInsightsOutput || (UpdateContributorInsightsOutput = {}));
var ReplicaAlreadyExistsException = (function (_super) {
    __extends(ReplicaAlreadyExistsException, _super);
    function ReplicaAlreadyExistsException(opts) {
        var _this = _super.call(this, __assign({ name: "ReplicaAlreadyExistsException", $fault: "client" }, opts)) || this;
        _this.name = "ReplicaAlreadyExistsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ReplicaAlreadyExistsException.prototype);
        return _this;
    }
    return ReplicaAlreadyExistsException;
}(__BaseException));
export { ReplicaAlreadyExistsException };
var ReplicaNotFoundException = (function (_super) {
    __extends(ReplicaNotFoundException, _super);
    function ReplicaNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "ReplicaNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "ReplicaNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ReplicaNotFoundException.prototype);
        return _this;
    }
    return ReplicaNotFoundException;
}(__BaseException));
export { ReplicaNotFoundException };
export var ReplicaUpdate;
(function (ReplicaUpdate) {
    ReplicaUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaUpdate || (ReplicaUpdate = {}));
export var UpdateGlobalTableInput;
(function (UpdateGlobalTableInput) {
    UpdateGlobalTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGlobalTableInput || (UpdateGlobalTableInput = {}));
export var UpdateGlobalTableOutput;
(function (UpdateGlobalTableOutput) {
    UpdateGlobalTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGlobalTableOutput || (UpdateGlobalTableOutput = {}));
var IndexNotFoundException = (function (_super) {
    __extends(IndexNotFoundException, _super);
    function IndexNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "IndexNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "IndexNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, IndexNotFoundException.prototype);
        return _this;
    }
    return IndexNotFoundException;
}(__BaseException));
export { IndexNotFoundException };
export var GlobalTableGlobalSecondaryIndexSettingsUpdate;
(function (GlobalTableGlobalSecondaryIndexSettingsUpdate) {
    GlobalTableGlobalSecondaryIndexSettingsUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalTableGlobalSecondaryIndexSettingsUpdate || (GlobalTableGlobalSecondaryIndexSettingsUpdate = {}));
export var ReplicaGlobalSecondaryIndexSettingsUpdate;
(function (ReplicaGlobalSecondaryIndexSettingsUpdate) {
    ReplicaGlobalSecondaryIndexSettingsUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaGlobalSecondaryIndexSettingsUpdate || (ReplicaGlobalSecondaryIndexSettingsUpdate = {}));
export var ReplicaSettingsUpdate;
(function (ReplicaSettingsUpdate) {
    ReplicaSettingsUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaSettingsUpdate || (ReplicaSettingsUpdate = {}));
export var UpdateGlobalTableSettingsInput;
(function (UpdateGlobalTableSettingsInput) {
    UpdateGlobalTableSettingsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGlobalTableSettingsInput || (UpdateGlobalTableSettingsInput = {}));
export var UpdateGlobalTableSettingsOutput;
(function (UpdateGlobalTableSettingsOutput) {
    UpdateGlobalTableSettingsOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGlobalTableSettingsOutput || (UpdateGlobalTableSettingsOutput = {}));
export var UpdateGlobalSecondaryIndexAction;
(function (UpdateGlobalSecondaryIndexAction) {
    UpdateGlobalSecondaryIndexAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGlobalSecondaryIndexAction || (UpdateGlobalSecondaryIndexAction = {}));
export var GlobalSecondaryIndexUpdate;
(function (GlobalSecondaryIndexUpdate) {
    GlobalSecondaryIndexUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalSecondaryIndexUpdate || (GlobalSecondaryIndexUpdate = {}));
export var UpdateReplicationGroupMemberAction;
(function (UpdateReplicationGroupMemberAction) {
    UpdateReplicationGroupMemberAction.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateReplicationGroupMemberAction || (UpdateReplicationGroupMemberAction = {}));
export var ReplicationGroupUpdate;
(function (ReplicationGroupUpdate) {
    ReplicationGroupUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicationGroupUpdate || (ReplicationGroupUpdate = {}));
export var UpdateTableInput;
(function (UpdateTableInput) {
    UpdateTableInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTableInput || (UpdateTableInput = {}));
export var UpdateTableOutput;
(function (UpdateTableOutput) {
    UpdateTableOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTableOutput || (UpdateTableOutput = {}));
export var GlobalSecondaryIndexAutoScalingUpdate;
(function (GlobalSecondaryIndexAutoScalingUpdate) {
    GlobalSecondaryIndexAutoScalingUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalSecondaryIndexAutoScalingUpdate || (GlobalSecondaryIndexAutoScalingUpdate = {}));
export var ReplicaGlobalSecondaryIndexAutoScalingUpdate;
(function (ReplicaGlobalSecondaryIndexAutoScalingUpdate) {
    ReplicaGlobalSecondaryIndexAutoScalingUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaGlobalSecondaryIndexAutoScalingUpdate || (ReplicaGlobalSecondaryIndexAutoScalingUpdate = {}));
export var ReplicaAutoScalingUpdate;
(function (ReplicaAutoScalingUpdate) {
    ReplicaAutoScalingUpdate.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ReplicaAutoScalingUpdate || (ReplicaAutoScalingUpdate = {}));
export var UpdateTableReplicaAutoScalingInput;
(function (UpdateTableReplicaAutoScalingInput) {
    UpdateTableReplicaAutoScalingInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTableReplicaAutoScalingInput || (UpdateTableReplicaAutoScalingInput = {}));
export var UpdateTableReplicaAutoScalingOutput;
(function (UpdateTableReplicaAutoScalingOutput) {
    UpdateTableReplicaAutoScalingOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTableReplicaAutoScalingOutput || (UpdateTableReplicaAutoScalingOutput = {}));
export var TimeToLiveSpecification;
(function (TimeToLiveSpecification) {
    TimeToLiveSpecification.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TimeToLiveSpecification || (TimeToLiveSpecification = {}));
export var UpdateTimeToLiveInput;
(function (UpdateTimeToLiveInput) {
    UpdateTimeToLiveInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTimeToLiveInput || (UpdateTimeToLiveInput = {}));
export var UpdateTimeToLiveOutput;
(function (UpdateTimeToLiveOutput) {
    UpdateTimeToLiveOutput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTimeToLiveOutput || (UpdateTimeToLiveOutput = {}));
export var AttributeValue;
(function (AttributeValue) {
    AttributeValue.visit = function (value, visitor) {
        if (value.S !== undefined)
            return visitor.S(value.S);
        if (value.N !== undefined)
            return visitor.N(value.N);
        if (value.B !== undefined)
            return visitor.B(value.B);
        if (value.SS !== undefined)
            return visitor.SS(value.SS);
        if (value.NS !== undefined)
            return visitor.NS(value.NS);
        if (value.BS !== undefined)
            return visitor.BS(value.BS);
        if (value.M !== undefined)
            return visitor.M(value.M);
        if (value.L !== undefined)
            return visitor.L(value.L);
        if (value.NULL !== undefined)
            return visitor.NULL(value.NULL);
        if (value.BOOL !== undefined)
            return visitor.BOOL(value.BOOL);
        return visitor._(value.$unknown[0], value.$unknown[1]);
    };
    AttributeValue.filterSensitiveLog = function (obj) {
        var _a;
        if (obj.S !== undefined)
            return { S: obj.S };
        if (obj.N !== undefined)
            return { N: obj.N };
        if (obj.B !== undefined)
            return { B: obj.B };
        if (obj.SS !== undefined)
            return { SS: obj.SS };
        if (obj.NS !== undefined)
            return { NS: obj.NS };
        if (obj.BS !== undefined)
            return { BS: obj.BS };
        if (obj.M !== undefined)
            return {
                M: Object.entries(obj.M).reduce(function (acc, _a) {
                    var _b;
                    var _c = __read(_a, 2), key = _c[0], value = _c[1];
                    return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
                }, {}),
            };
        if (obj.L !== undefined)
            return { L: obj.L.map(function (item) { return AttributeValue.filterSensitiveLog(item); }) };
        if (obj.NULL !== undefined)
            return { NULL: obj.NULL };
        if (obj.BOOL !== undefined)
            return { BOOL: obj.BOOL };
        if (obj.$unknown !== undefined)
            return _a = {}, _a[obj.$unknown[0]] = "UNKNOWN", _a;
    };
})(AttributeValue || (AttributeValue = {}));
export var AttributeValueUpdate;
(function (AttributeValueUpdate) {
    AttributeValueUpdate.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Value && { Value: AttributeValue.filterSensitiveLog(obj.Value) }))); };
})(AttributeValueUpdate || (AttributeValueUpdate = {}));
export var BatchStatementRequest;
(function (BatchStatementRequest) {
    BatchStatementRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Parameters && { Parameters: obj.Parameters.map(function (item) { return AttributeValue.filterSensitiveLog(item); }) }))); };
})(BatchStatementRequest || (BatchStatementRequest = {}));
export var BatchStatementResponse;
(function (BatchStatementResponse) {
    BatchStatementResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(BatchStatementResponse || (BatchStatementResponse = {}));
export var CancellationReason;
(function (CancellationReason) {
    CancellationReason.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(CancellationReason || (CancellationReason = {}));
export var Condition;
(function (Condition) {
    Condition.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AttributeValueList && {
        AttributeValueList: obj.AttributeValueList.map(function (item) { return AttributeValue.filterSensitiveLog(item); }),
    }))); };
})(Condition || (Condition = {}));
export var DeleteRequest;
(function (DeleteRequest) {
    DeleteRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(DeleteRequest || (DeleteRequest = {}));
export var ExecuteStatementInput;
(function (ExecuteStatementInput) {
    ExecuteStatementInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Parameters && { Parameters: obj.Parameters.map(function (item) { return AttributeValue.filterSensitiveLog(item); }) }))); };
})(ExecuteStatementInput || (ExecuteStatementInput = {}));
export var Get;
(function (Get) {
    Get.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(Get || (Get = {}));
export var GetItemInput;
(function (GetItemInput) {
    GetItemInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(GetItemInput || (GetItemInput = {}));
export var GetItemOutput;
(function (GetItemOutput) {
    GetItemOutput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(GetItemOutput || (GetItemOutput = {}));
export var ItemCollectionMetrics;
(function (ItemCollectionMetrics) {
    ItemCollectionMetrics.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ItemCollectionKey && {
        ItemCollectionKey: Object.entries(obj.ItemCollectionKey).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(ItemCollectionMetrics || (ItemCollectionMetrics = {}));
export var ItemResponse;
(function (ItemResponse) {
    ItemResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(ItemResponse || (ItemResponse = {}));
export var ParameterizedStatement;
(function (ParameterizedStatement) {
    ParameterizedStatement.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Parameters && { Parameters: obj.Parameters.map(function (item) { return AttributeValue.filterSensitiveLog(item); }) }))); };
})(ParameterizedStatement || (ParameterizedStatement = {}));
export var PutRequest;
(function (PutRequest) {
    PutRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(PutRequest || (PutRequest = {}));
export var KeysAndAttributes;
(function (KeysAndAttributes) {
    KeysAndAttributes.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Keys && {
        Keys: obj.Keys.map(function (item) {
            return Object.entries(item).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), key = _c[0], value = _c[1];
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
            }, {});
        }),
    }))); };
})(KeysAndAttributes || (KeysAndAttributes = {}));
export var TransactGetItem;
(function (TransactGetItem) {
    TransactGetItem.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Get && { Get: Get.filterSensitiveLog(obj.Get) }))); };
})(TransactGetItem || (TransactGetItem = {}));
export var BatchExecuteStatementInput;
(function (BatchExecuteStatementInput) {
    BatchExecuteStatementInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Statements && { Statements: obj.Statements.map(function (item) { return BatchStatementRequest.filterSensitiveLog(item); }) }))); };
})(BatchExecuteStatementInput || (BatchExecuteStatementInput = {}));
export var BatchExecuteStatementOutput;
(function (BatchExecuteStatementOutput) {
    BatchExecuteStatementOutput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Responses && { Responses: obj.Responses.map(function (item) { return BatchStatementResponse.filterSensitiveLog(item); }) }))); };
})(BatchExecuteStatementOutput || (BatchExecuteStatementOutput = {}));
export var ExecuteTransactionInput;
(function (ExecuteTransactionInput) {
    ExecuteTransactionInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.TransactStatements && {
        TransactStatements: obj.TransactStatements.map(function (item) { return ParameterizedStatement.filterSensitiveLog(item); }),
    }))); };
})(ExecuteTransactionInput || (ExecuteTransactionInput = {}));
export var ExecuteTransactionOutput;
(function (ExecuteTransactionOutput) {
    ExecuteTransactionOutput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Responses && { Responses: obj.Responses.map(function (item) { return ItemResponse.filterSensitiveLog(item); }) }))); };
})(ExecuteTransactionOutput || (ExecuteTransactionOutput = {}));
export var TransactGetItemsOutput;
(function (TransactGetItemsOutput) {
    TransactGetItemsOutput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Responses && { Responses: obj.Responses.map(function (item) { return ItemResponse.filterSensitiveLog(item); }) }))); };
})(TransactGetItemsOutput || (TransactGetItemsOutput = {}));
var TransactionCanceledException = (function (_super) {
    __extends(TransactionCanceledException, _super);
    function TransactionCanceledException(opts) {
        var _this = _super.call(this, __assign({ name: "TransactionCanceledException", $fault: "client" }, opts)) || this;
        _this.name = "TransactionCanceledException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TransactionCanceledException.prototype);
        _this.Message = opts.Message;
        _this.CancellationReasons = opts.CancellationReasons;
        return _this;
    }
    return TransactionCanceledException;
}(__BaseException));
export { TransactionCanceledException };
export var BatchGetItemInput;
(function (BatchGetItemInput) {
    BatchGetItemInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RequestItems && {
        RequestItems: Object.entries(obj.RequestItems).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = KeysAndAttributes.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(BatchGetItemInput || (BatchGetItemInput = {}));
export var ExpectedAttributeValue;
(function (ExpectedAttributeValue) {
    ExpectedAttributeValue.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Value && { Value: AttributeValue.filterSensitiveLog(obj.Value) })), (obj.AttributeValueList && {
        AttributeValueList: obj.AttributeValueList.map(function (item) { return AttributeValue.filterSensitiveLog(item); }),
    }))); };
})(ExpectedAttributeValue || (ExpectedAttributeValue = {}));
export var TransactGetItemsInput;
(function (TransactGetItemsInput) {
    TransactGetItemsInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.TransactItems && {
        TransactItems: obj.TransactItems.map(function (item) { return TransactGetItem.filterSensitiveLog(item); }),
    }))); };
})(TransactGetItemsInput || (TransactGetItemsInput = {}));
export var TransactWriteItemsOutput;
(function (TransactWriteItemsOutput) {
    TransactWriteItemsOutput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ItemCollectionMetrics && {
        ItemCollectionMetrics: Object.entries(obj.ItemCollectionMetrics).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = value.map(function (item) { return ItemCollectionMetrics.filterSensitiveLog(item); }), _b)));
        }, {}),
    }))); };
})(TransactWriteItemsOutput || (TransactWriteItemsOutput = {}));
export var ConditionCheck;
(function (ConditionCheck) {
    ConditionCheck.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(ConditionCheck || (ConditionCheck = {}));
export var Delete;
(function (Delete) {
    Delete.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(Delete || (Delete = {}));
export var Put;
(function (Put) {
    Put.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(Put || (Put = {}));
export var Update;
(function (Update) {
    Update.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(Update || (Update = {}));
export var DeleteItemOutput;
(function (DeleteItemOutput) {
    DeleteItemOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Attributes && {
        Attributes: Object.entries(obj.Attributes).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ItemCollectionMetrics && {
        ItemCollectionMetrics: ItemCollectionMetrics.filterSensitiveLog(obj.ItemCollectionMetrics),
    }))); };
})(DeleteItemOutput || (DeleteItemOutput = {}));
export var ExecuteStatementOutput;
(function (ExecuteStatementOutput) {
    ExecuteStatementOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Items && {
        Items: obj.Items.map(function (item) {
            return Object.entries(item).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), key = _c[0], value = _c[1];
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
            }, {});
        }),
    })), (obj.LastEvaluatedKey && {
        LastEvaluatedKey: Object.entries(obj.LastEvaluatedKey).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(ExecuteStatementOutput || (ExecuteStatementOutput = {}));
export var PutItemOutput;
(function (PutItemOutput) {
    PutItemOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Attributes && {
        Attributes: Object.entries(obj.Attributes).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ItemCollectionMetrics && {
        ItemCollectionMetrics: ItemCollectionMetrics.filterSensitiveLog(obj.ItemCollectionMetrics),
    }))); };
})(PutItemOutput || (PutItemOutput = {}));
export var QueryOutput;
(function (QueryOutput) {
    QueryOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Items && {
        Items: obj.Items.map(function (item) {
            return Object.entries(item).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), key = _c[0], value = _c[1];
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
            }, {});
        }),
    })), (obj.LastEvaluatedKey && {
        LastEvaluatedKey: Object.entries(obj.LastEvaluatedKey).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(QueryOutput || (QueryOutput = {}));
export var ScanOutput;
(function (ScanOutput) {
    ScanOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Items && {
        Items: obj.Items.map(function (item) {
            return Object.entries(item).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), key = _c[0], value = _c[1];
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
            }, {});
        }),
    })), (obj.LastEvaluatedKey && {
        LastEvaluatedKey: Object.entries(obj.LastEvaluatedKey).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(ScanOutput || (ScanOutput = {}));
export var UpdateItemOutput;
(function (UpdateItemOutput) {
    UpdateItemOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Attributes && {
        Attributes: Object.entries(obj.Attributes).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ItemCollectionMetrics && {
        ItemCollectionMetrics: ItemCollectionMetrics.filterSensitiveLog(obj.ItemCollectionMetrics),
    }))); };
})(UpdateItemOutput || (UpdateItemOutput = {}));
export var WriteRequest;
(function (WriteRequest) {
    WriteRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.PutRequest && { PutRequest: PutRequest.filterSensitiveLog(obj.PutRequest) })), (obj.DeleteRequest && { DeleteRequest: DeleteRequest.filterSensitiveLog(obj.DeleteRequest) }))); };
})(WriteRequest || (WriteRequest = {}));
export var BatchGetItemOutput;
(function (BatchGetItemOutput) {
    BatchGetItemOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Responses && {
        Responses: Object.entries(obj.Responses).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = value.map(function (item) {
                return Object.entries(item).reduce(function (acc, _a) {
                    var _b;
                    var _c = __read(_a, 2), key = _c[0], value = _c[1];
                    return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
                }, {});
            }), _b)));
        }, {}),
    })), (obj.UnprocessedKeys && {
        UnprocessedKeys: Object.entries(obj.UnprocessedKeys).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = KeysAndAttributes.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(BatchGetItemOutput || (BatchGetItemOutput = {}));
export var ScanInput;
(function (ScanInput) {
    ScanInput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ScanFilter && {
        ScanFilter: Object.entries(obj.ScanFilter).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = Condition.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExclusiveStartKey && {
        ExclusiveStartKey: Object.entries(obj.ExclusiveStartKey).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(ScanInput || (ScanInput = {}));
export var BatchWriteItemInput;
(function (BatchWriteItemInput) {
    BatchWriteItemInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RequestItems && {
        RequestItems: Object.entries(obj.RequestItems).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = value.map(function (item) { return WriteRequest.filterSensitiveLog(item); }), _b)));
        }, {}),
    }))); };
})(BatchWriteItemInput || (BatchWriteItemInput = {}));
export var DeleteItemInput;
(function (DeleteItemInput) {
    DeleteItemInput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.Expected && {
        Expected: Object.entries(obj.Expected).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = ExpectedAttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(DeleteItemInput || (DeleteItemInput = {}));
export var PutItemInput;
(function (PutItemInput) {
    PutItemInput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Item && {
        Item: Object.entries(obj.Item).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.Expected && {
        Expected: Object.entries(obj.Expected).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = ExpectedAttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(PutItemInput || (PutItemInput = {}));
export var QueryInput;
(function (QueryInput) {
    QueryInput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.KeyConditions && {
        KeyConditions: Object.entries(obj.KeyConditions).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = Condition.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.QueryFilter && {
        QueryFilter: Object.entries(obj.QueryFilter).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = Condition.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExclusiveStartKey && {
        ExclusiveStartKey: Object.entries(obj.ExclusiveStartKey).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(QueryInput || (QueryInput = {}));
export var BatchWriteItemOutput;
(function (BatchWriteItemOutput) {
    BatchWriteItemOutput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.UnprocessedItems && {
        UnprocessedItems: Object.entries(obj.UnprocessedItems).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = value.map(function (item) { return WriteRequest.filterSensitiveLog(item); }), _b)));
        }, {}),
    })), (obj.ItemCollectionMetrics && {
        ItemCollectionMetrics: Object.entries(obj.ItemCollectionMetrics).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = value.map(function (item) { return ItemCollectionMetrics.filterSensitiveLog(item); }), _b)));
        }, {}),
    }))); };
})(BatchWriteItemOutput || (BatchWriteItemOutput = {}));
export var UpdateItemInput;
(function (UpdateItemInput) {
    UpdateItemInput.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.Key && {
        Key: Object.entries(obj.Key).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.AttributeUpdates && {
        AttributeUpdates: Object.entries(obj.AttributeUpdates).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValueUpdate.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.Expected && {
        Expected: Object.entries(obj.Expected).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = ExpectedAttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    })), (obj.ExpressionAttributeValues && {
        ExpressionAttributeValues: Object.entries(obj.ExpressionAttributeValues).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = AttributeValue.filterSensitiveLog(value), _b)));
        }, {}),
    }))); };
})(UpdateItemInput || (UpdateItemInput = {}));
export var TransactWriteItem;
(function (TransactWriteItem) {
    TransactWriteItem.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.ConditionCheck && { ConditionCheck: ConditionCheck.filterSensitiveLog(obj.ConditionCheck) })), (obj.Put && { Put: Put.filterSensitiveLog(obj.Put) })), (obj.Delete && { Delete: Delete.filterSensitiveLog(obj.Delete) })), (obj.Update && { Update: Update.filterSensitiveLog(obj.Update) }))); };
})(TransactWriteItem || (TransactWriteItem = {}));
export var TransactWriteItemsInput;
(function (TransactWriteItemsInput) {
    TransactWriteItemsInput.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.TransactItems && {
        TransactItems: obj.TransactItems.map(function (item) { return TransactWriteItem.filterSensitiveLog(item); }),
    }))); };
})(TransactWriteItemsInput || (TransactWriteItemsInput = {}));
