import { __assign, __awaiter, __generator, __read } from "tslib";
export var getCacheKey = function (commandName, config, options) { return __awaiter(void 0, void 0, void 0, function () {
    var accessKeyId, identifiers;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, config.credentials()];
            case 1:
                accessKeyId = (_a.sent()).accessKeyId;
                identifiers = options.identifiers;
                return [2, JSON.stringify(__assign(__assign({}, (accessKeyId && { accessKeyId: accessKeyId })), (identifiers && {
                        commandName: commandName,
                        identifiers: Object.entries(identifiers)
                            .sort()
                            .reduce(function (acc, _a) {
                            var _b;
                            var _c = __read(_a, 2), key = _c[0], value = _c[1];
                            return (__assign(__assign({}, acc), (_b = {}, _b[key] = value, _b)));
                        }, {}),
                    })))];
        }
    });
}); };
