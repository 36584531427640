import LRUCache from "mnemonist/lru-cache";
var EndpointCache = (function () {
    function EndpointCache(capacity) {
        this.cache = new LRUCache(capacity);
    }
    EndpointCache.prototype.getEndpoint = function (key) {
        var endpointsWithExpiry = this.get(key);
        if (!endpointsWithExpiry || endpointsWithExpiry.length === 0) {
            return undefined;
        }
        var endpoints = endpointsWithExpiry.map(function (endpoint) { return endpoint.Address; });
        return endpoints[Math.floor(Math.random() * endpoints.length)];
    };
    EndpointCache.prototype.get = function (key) {
        if (!this.has(key)) {
            return;
        }
        var value = this.cache.get(key);
        if (!value) {
            return;
        }
        var now = Date.now();
        var endpointsWithExpiry = value.filter(function (endpoint) { return now < endpoint.Expires; });
        if (endpointsWithExpiry.length === 0) {
            this.delete(key);
            return undefined;
        }
        return endpointsWithExpiry;
    };
    EndpointCache.prototype.set = function (key, endpoints) {
        var now = Date.now();
        this.cache.set(key, endpoints.map(function (_a) {
            var Address = _a.Address, CachePeriodInMinutes = _a.CachePeriodInMinutes;
            return ({
                Address: Address,
                Expires: now + CachePeriodInMinutes * 60 * 1000,
            });
        }));
    };
    EndpointCache.prototype.delete = function (key) {
        this.cache.set(key, []);
    };
    EndpointCache.prototype.has = function (key) {
        if (!this.cache.has(key)) {
            return false;
        }
        var endpoints = this.cache.peek(key);
        if (!endpoints) {
            return false;
        }
        return endpoints.length > 0;
    };
    EndpointCache.prototype.clear = function () {
        this.cache.clear();
    };
    return EndpointCache;
}());
export { EndpointCache };
